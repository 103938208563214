<template>
	<main>
		<div class="header">
			<div class="title-box">
				<a-link to="/sentry" class="title">{{title}}</a-link>
				<div class="project" v-if="project">{{project.title}}</div>
			</div>
			<div class="action-box">
				<div class="action"><el-button class="item" type="primary" @click="showCreate">创建项目</el-button></div>
				<div class="user" @click="loginOut">
					<div class="avatar">{{avatar}}</div>
					<div class="name">{{ account_info.nickname }}</div>
				</div>
			</div>
		</div>
		<el-dialog title="创建项目" :visible="show_create" @close="hideCreate">
			<el-form :model="form" v-if="show_create" :rules="rules" ref="form" label-width="100px">
				<div class="form-flex">
					<el-form-item label="项目名称" prop="title">
						<el-input v-model="form.title"></el-input>
					</el-form-item>
					<el-form-item label="项目类型" prop="type">
						<el-select class="el-block" v-model="form.type" placeholder="请选择">
							<el-option label="JAVASCRIPT" value="js"></el-option>
							<el-option label="VUE" value="vue"></el-option>
							<el-option label="VUE3" value="vue"></el-option>
							<el-option label="REACT" value="react"></el-option>
							<el-option label="WXAPP" value="wxapp"></el-option>
						</el-select>
					</el-form-item>
				</div>
				<div class="form-flex">
					<el-form-item label="监控console" prop="console">
						<el-switch v-model="form.console"></el-switch>
					</el-form-item>
					<el-form-item label="监控xhr" prop="xhr">
						<el-switch v-model="form.xhr"></el-switch>
					</el-form-item>
				</div>
				<div class="form-flex">
					<el-form-item label="监控fetch" prop="console">
						<el-switch v-model="form.fetch"></el-switch>
					</el-form-item>
					<el-form-item label="行为记录" prop="max_bread">
						<el-input-number v-model="form.max_bread" :max="100" placeholder="20"></el-input-number>
					</el-form-item>
				</div>
				<el-form-item label="忽略接口地址" prop="filter_ajax">
					<el-input v-model="form.filter_ajax" placeholder="支持正则"></el-input>
				</el-form-item>
				<div class="form-flex">
					<el-form-item label="状态" prop="status">
						<el-switch v-model="form.status" active-text="正常" inactive-text="停用"></el-switch>
					</el-form-item>
					<el-form-item label="权重" prop="sort">
						<el-input v-model="form.sort" type="number"></el-input>
					</el-form-item>
				</div>
				<div class="form-flex">
					<el-form-item label="发布时间" prop="create_time">
						<el-date-picker v-model="form.create_time" type="datetime" placeholder="选择日期时间"></el-date-picker>
					</el-form-item>
					<el-form-item label="停用时间" prop="end_time">
						<el-date-picker v-model="form.end_time" type="datetime" placeholder="选择日期时间"></el-date-picker>
					</el-form-item>
				</div>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="hideCreate">取 消</el-button>
				<el-button type="primary" :loading="submit_loading" @click="submit">确 定</el-button>
			</div>
		</el-dialog>
	</main>
</template>

<script>
import {auth,project} from "@/api/sentry";
import {MessageBox} from "element-ui"
import {site_config} from "@/config/index.js";
import _ from "lodash";
import {stringToTime} from "@/service/admin/common";

export default {
	props:{
		collapse:false,
	},
	data(){
		return {
			show_create:false,
			form:{},
			submit_loading:false,
		}
	},
	computed:{
		rules(){
			return {
				title:[
					{required:true,message:"请输入名称"}
				],
				type:[
					{required:true,message:"请选择类型"}
				]
			}
		},
		account_info(){
			return this.$store.state.sentry.account_info;
		},
		avatar(){
			return this.account_info.username ? this.account_info.username.split('')[0] : ''
		},
		title(){
			return `${site_config.title}哨兵监控系统`
		},
		project(){
			return this.$store.state.sentry.project
		}
	},
	methods:{
		showCreate(){
			this.show_create = true;
		},
		hideCreate(){
			this.show_create = false;
			this.form = {};
		},
		submit(){
			this.$refs['form'].validate((valid) => {
				if (!valid) {
					return false;
				}
				var data = _.cloneDeep(this.form);
				data.status = data.status ? 1 : 2;
				data.console = data.console ? 1 : 2;
				data.xhr = data.xhr ? 1 : 2;
				data.fetch = data.fetch ? 1 : 2;
				data.create_time = stringToTime(data.create_time);
				data.end_time = stringToTime(data.end_time);

				this.submit_loading = true;
				project.create(data).then((res)=>{
					this.hideCreate();
					this.$message.success({
						message:'保存成功',
						duration:1500,
					});
				}).finally(()=>{
					this.submit_loading = false;
				})
			});
		},
		loginOut(){
			MessageBox.confirm('确定要退出登录吗？','提示',{
				type:"warning"
			}).then((res)=>{
				auth.logout().then((res)=>{
					this.$store.commit('sentry/setToken','')
					this.$router.push('/sentry/auth/login')
				})
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.header{height:56px;display: flex;align-items: center;justify-content: space-between;padding: 0 15px;box-shadow: 0 0 5px 5px rgba(0,0,0,.1);position: relative;z-index: $z-header;
	.title-box{display: flex;align-items:center;
		.title{font-size: 20px;font-weight: bold;color:$admin_main;}
		.project{margin-left: 15px;}

	}
	.action-box{display: flex;align-items: center;
		.action{font-size: 14px;
			.item{cursor: pointer;margin-right: 15px;}
		}
		.user{margin-left: 30px;display: flex;align-items: center;cursor: pointer;
			.avatar{width: 30px;height:30px;background: $admin_main;border-radius: 50%;font-size: 16px;color:#fff;text-align: center;line-height: 30px;text-transform: uppercase;}
			.name{margin-left: 10px;font-size: 16px;flex: 1;}
		}
	}
}
.el-block{display: block;}
.form-flex{display: flex;
	.el-form-item{flex: 1;}
}
</style>

