<template>
	<div id="app">
		<div class="app-content">
			<div class="app-header">
				<components-header />
			</div>
			<div class="app-container">
				<div class="content">
					<router-view :key="route_key"></router-view>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ComponentsHeader from "@/components/layout/sentry/Header.vue";
import {site_config} from "@/config";
import {isDev} from "@/common/other";
import "@/plugins/admin"
import {account} from "@/api/sentry";
export default {
	components:{ComponentsHeader},
	computed:{
		route_key(){
			return this.$route.fullPath
		},
		meta(){
			return this.$route.meta;
		}
	},
	created() {
		document.title = `${site_config.title}哨兵监控系统`
		this.getAdminInfo();
	},
	methods:{
		isDev,
		getAdminInfo(){
			account.info().then((res)=>{
				this.$store.commit('sentry/setAccountInfo',res.account);
			})
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/admin/css/reset.scss';
@import '@/assets/admin/css/ricktext.scss';
</style>
<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {transition: all .3s ease;}
.fade-enter-from, .fade-leave-to {opacity: 0;transition: all .3s ease;}
#app{position: relative;min-height: 100vh;display: flex;
	.app-content{flex: 1;overflow: hidden;min-width: 992px;
		.app-container{
			.content{background: #fff;}
		}
	}
}
</style>
